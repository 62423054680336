import React from "react";


function Loader() {
  return (
    <>
      <div className="w-full h-[100vh] bg-black flex justify-center items-center">
        {/* <div className="loader"></div> */}
        <img 
        src="https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/LogosAnimados%2FInfinity-Thrift-Store_-2.gif?alt=media&token=75ea33a5-614c-4e54-8cd9-b2e5f3757b95" 
        alt="loader" 
        className="w-1/2 md:w-[40%] object-contain"

        />
      </div>
    </>
  );
}

export default Loader;

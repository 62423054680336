import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context';
import { Link } from 'react-router-dom'
import Navmenu from './NavMenu';


const HeaderOne = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div>

            {
                rpdata?.simpleWidgets?.[3]?.activo ?
                    <div className='absolute right-[5%] left-[5%] z-50 pt-10  flex justify-center items-center'>
                        {/* logo */}
                        <div className='w-[70%] md:w-[20%] pr-3 md:pr-0'>
                            <Link to={'/'}>
                                <img
                                    src={`${rpdata?.dbPrincipal?.logo}`}
                                    alt='no found'
                                    className='w-full'
                                />
                            </Link>
                        </div>
                    </div>
                    :
                    <>
                        <div className='relative z-50  flex justify-between items-center bg-black w-full h-auto'>
                            {/* logo */}
                            <div className='w-[70%] md:w-auto p-3 '>
                                <Link to={'/'}>
                                    <img
                                        src={`${rpdata?.dbPrincipal?.logo}`}
                                        alt='no found'
                                        className='w-[180px] h-[140px] object-contain'
                                    />
                                </Link>
                            </div>
                            {/* nav menu */}
                            <nav className='w-[20%] md:w-[50%] flex h-full items-end justify-start '>
                                <div className='relative'>
                                    <Navmenu />
                                </div>
                            </nav>
                            <div className='w-[34%] hidden md:flex h-full items-end justify-start '>
                                <div className='w-3/5 flex flex-col md:flex-row space-x-0 md:space-x-2 '>
                                    {
                                        rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                            return (
                                                <a className='w-full ' href={`tel:+1${phone.phone}`} key={index}>
                                                    <div className='py-5  px-2 flex bgborder '>
                                                        <span className=" text-white font-bold">
                                                            {phone.phone}
                                                        </span>
                                                    </div>
                                                </a>
                                            )
                                        })


                                    }
                                </div>
                                <div className='w-[30%] border ml-4'>
                                    <Link to={'/contact'}>
                                        <div className='py-5 px-2 flex bgborder justify-center'>
                                            <span className=" text-white font-bold">
                                                Contact Us
                                            </span>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div className='w-full md:hidden flex h-full items-end justify-start bg-black'>
                            <div className='w-full flex flex-row space-x-0 md:space-x-2  px-5'>
                                {
                                    rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                        return (
                                            <a className='w-full ' href={`tel:+1${phone.phone}`} key={index}>
                                                <div className='py-5  px-2 flex bgborder '>
                                                    <span className=" text-white font-bold">
                                                        {phone.phone}
                                                    </span>
                                                </div>
                                            </a>
                                        )
                                    })


                                }
                            </div>
                            <div className='w-[30%] border ml-4 hidden '>
                                <Link to={'/contact'}>
                                    <div className='py-5 px-2 flex bgborder justify-center'>
                                        <span className=" text-white font-bold">
                                            Contact Us
                                        </span>
                                    </div>
                                </Link>
                            </div>

                        </div>
                    </>

            }
        </div>
    )
}

export default HeaderOne
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import ScrollButton from "./BackDonw";


function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[5]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <div className="z-10 relative flex justify-center items-end w-4/5 mx-auto h-[250px] md:h-[75vh]">
          <ScrollButton />
        </div>
      </div>
    </>
  );
}

export default HeroSection;

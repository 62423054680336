import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

const ScrollButton = () => {
  const handleScroll = () => {
    window.scrollBy({ top: 500, behavior: 'smooth' });
  };

  return (
    <button
      onClick={handleScroll}
      className="bg-black text-white font-bold py-2 px-6"
    >
      <FaChevronDown className='animate-upDown' size={26}/>
    </button>
  );
};

export default ScrollButton;
